<template>
  <div class="index">
    <div class="swiperBox">
      <div class="box">
        <swiper :options="swiperOption" ref="mySwiper" v-if="swiperList.length>0">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in swiperList"
            :key="index"
          >
            <img :src="item.imgSrc" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-pagination" slot="pagination"></div>
    </div>
    <div class="production wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>产品及方案</span>
          </div>
        </div>
        <div class="content">
          <div class="left_one">
            <div class="images"></div>
            <div class="one">智慧广域网</div>
            <div class="two">解放你的网络管理</div>
            <div class="three">
              通过智慧的骨干网络可以将您的分支机构快速高效的连成一体，并集中管理企业的网络规划，维护网路安全。
            </div>
          </div>
          <div class="left_two">
            <div class="images"></div>
            <div class="one">高速云联</div>
            <div class="two">搭建通往公有/私有云的快速通道</div>
            <div class="three">
              传统互联网无法满足混合云部署的企业实现私有且安全的链接方式来访问公有/私有云
            </div>
          </div>
          <div class="left_three">
            <div class="images"></div>
            <div class="one">虚拟专用网</div>
            <div class="two">专网专用，安全稳定</div>
            <div class="three">
              为企业提供安全稳定的私有虚拟专用网络服务，快速实现跨域网络互联
            </div>
          </div>
          <div class="left_four">
            <div class="images"></div>
            <div class="one">边缘数据中心</div>
            <div class="two">绿色节能，技术革新</div>
            <div class="three">
              有效利用自然空气和蒸发冷却系统，最大限度地帮助客户提高IT基础设施的生产力，广泛部署在小城市、智能企业、智能工厂、智能家庭等
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PC服务优势 -->
    <div
      class="service hidden-xs-only wow animate__animated animate__fadeInDown"
    >
      <div class="section">
        <div class="title">
          <div class="title_one">服务优势</div>
        </div>
        <div class="content">
          <div class="left">
            <img src="../../assets/images/index/index_6.png" alt="" />
            <div class="one">丰富的网络资源</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_7.png" alt="" />
            <div class="one">全面的网络覆盖</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_8.png" alt="" />
            <div class="one">一步入云</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_9.png" alt="" />
            <div class="one">多种数据中心托管</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_10.png" alt="" />
            <div class="one">全球化的解决方案</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 手机服务优势 -->
    <div
      class="
        mobile_service
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <div class="section">
        <div class="title">
          <div class="title_one">服务优势</div>
        </div>
        <div class="content">
          <div class="left">
            <img src="../../assets/images/index/index_6.png" alt="" />
            <div class="one">丰富的网络资源</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_7.png" alt="" />
            <div class="one">全面的网络覆盖</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_8.png" alt="" />
            <div class="one">一步入云</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_9.png" alt="" />
            <div class="one">多种数据中心托管</div>
          </div>
          <div class="left">
            <img src="../../assets/images/index/index_10.png" alt="" />
            <div class="one">全球化的解决方案</div>
          </div>
        </div>
      </div>
    </div>
    <div class="transfer wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>网络节点覆盖</span>
          </div>
        </div>
        <div class="content">
          <!-- <img src="../../assets/images/index/index_11.png" alt="" /> -->
          <Map></Map>
        </div>
      </div>
    </div>
    <!-- PC新闻中心 -->
    <div class="news hidden-xs-only wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>新闻中心</span>
          </div>
        </div>
        <div class="content" v-if="newsList.length!==0">
          <div class="box">
            <swiper :options="swiperOptionTwo" ref="mySwiperTwo">
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in newsList"
                :key="index"
              >
                <div class="img" @click="goDetail(item)">
                  <img v-if="item.picture" :src="url + item.picture.fileUrl" alt="">
                  <img v-else src="../../assets/images/index/none.png" alt="">
                </div>
                <div class="middle" @click="goDetail(item)">
                  <div class="itemTitle">{{ item.title }}</div>
                  <div class="itemContent" v-html="item.profile"></div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="swiper-paginations" slot="pagination"></div>
        </div>
      </div>
    </div>
    <!-- 手机新闻中心 -->
    <div class="mobile_news hidden-sm-and-up wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>新闻中心</span>
          </div>
        </div>
        <div class="content" v-if="newsList.length!==0">
          <swiper :options="swiperOptionMobileTwo" ref="myMobileSwiperTwo"  v-if="newsList.length>0">
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in newsList"
                :key="index"
              >
                <div class="img" @click="goDetail(item)">
                  <img v-if="item.picture" :src="url + item.picture.fileUrl" alt="">
                  <img v-else src="../../assets/images/index/none.png" alt="">
                </div>
                <div class="middle" @click="goDetail(item)">
                  <div class="itemTitle">{{ item.title }}</div>
                  <div class="itemContent" v-html="item.profile"></div>
                </div>
              </swiper-slide>
          </swiper>
          <div class="swiper-paginations" slot="pagination"></div>
        </div>
      </div>
    </div>
    <!-- PC我们的客户 -->
    <div class="customer hidden-xs-only wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>我们的伙伴</span>
          </div>
        </div>
        <div class="content">
          <swiper :options="swiperOptionThree" ref="mySwiperThree" v-if="swiperListThree.length>0">
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in swiperListThree"
              :key="index"
            >
              <img :src="item.imgSrc" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev">
              <img src="../../assets/images/index/left.png" alt="">
            </div>
            <div class="swiper-button-next" slot="button-next">
              <img src="../../assets/images/index/right.png" alt="">
            </div>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 手机我们的客户 -->
    <div class="mobile_customer hidden-sm-and-up wow animate__animated animate__fadeInDown">
      <div class="section">
        <div class="title">
          <div class="title_one">
            <span>我们的伙伴</span>
          </div>
        </div>
        <div class="content">
          <swiper :options="swiperOptionMobileThree" ref="mySwiperMobileThree" v-if="swiperListThree.length>0">
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in swiperListThree"
              :key="index"
            >
              <img :src="item.imgSrc" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev">
              <img src="../../assets/images/index/left.png" alt="">
            </div>
            <div class="swiper-button-next" slot="button-next">
              <img src="../../assets/images/index/right.png" alt="">
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Map from '../../components/map.vue'
import { noticePage } from '../../api/management'
export default {
  components: { Map },
  data() {
    return {
      url:process.env.VUE_APP_WEB_URL,
      Info:{
        pageSize:1000,
        current:1,
        displaySeen:true
      },
      newsList:[],
      swiperList: [],
      swiperOption: {
        loop: true, //是否循环轮播
        autoplay: true, //可选选项，自动滑动
        autoplayDisableOnInteraction: true,
        observer: true,
        observeParents:true,//修改swiper的父元素时，自动初始化swiper 
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: function() {
              this.autoplay.start();//就加这个玩意儿
          },
        }
      },
      // 新闻
      swiperListTwo: [],
      swiperOptionTwo: {
        loop: true, //是否循环轮播
        autoplay: true, //可选选项，自动滑动
        autoplayDisableOnInteraction: true,
        observer: true,
        spaceBetween: 20,
        slidesPerView: 3,
        pagination: {
          el: ".swiper-paginations",
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: function() {
              this.autoplay.start();//拖拽之后继续轮播
          },
        }
      },
      swiperListThree: [],
      swiperOptionThree: {
        speed:1500,
        loop: true, //是否循环轮播
        // autoplay: true, //可选选项，自动滑动
        autoplay:{
          delay:200
        },
        slidesPerView: 5,
        spaceBetween: 15,
        //左右切换
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: function() {
              this.autoplay.start();//就加这个玩意儿
          },
        }
      },
      swiperOptionMobileThree: {
        speed:1500,
        loop: true, //是否循环轮播
        // autoplay: true, //可选选项，自动滑动
        slidesPerView: 3,
        spaceBetween: 10,
        autoplay:{
          delay:200
        },
        //左右切换
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: function() {
              this.autoplay.start();//就加这个玩意儿
          },
        }
      },
      swiperOptionMobileTwo: {
        slidesPerView: 1.7, // 设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: 0,
        centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左
        slideToClickedSlide: true, // true:点击slide会过渡到这个slide,默认false
        pagination: {
          el: ".swiper-paginations",
          clickable: true,
        },
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        autoplay: true,
        // effect: 'coverflow', // 类型卡片
        coverflowEffect: {
          slideShadows: false, // 页面阴影效果
          rotate: 0, // 旋转的角度
          stretch: 1000, // 拉伸 图片间左右的间距和密集度，越大靠得越近
          depth: 300, // 深度 切换图片间上下的间距和密集度，值越大z轴距离越远，看起来越小。
          modifier: 0.8 // 修正值 该值越大前面的效果越明显
        },
        on: {
          slideChangeTransitionEnd: function() {
              this.autoplay.start();//就加这个玩意儿
          },
        }
        // 左右两边的点击事件
      }
    };
  },
  mounted() {
    new this.$wow.WOW().init();
    this.getPCorMobile();
    this.getSwiperListTwo();
    this.getSwiperListThree();
    this.getNoticePage()
  },
  // created() {
  //   console.log(111)
  // },
  methods: {
    // 新闻
    async getNoticePage() {
        const res = await noticePage(this.Info)
        // console.log(res);
        this.newsList = res.data.records
    },
    getSwiperList() {
      this.swiperList = [
        {
          imgSrc: require("../../assets/images/swiper/swiper_1.jpg"),
        },
        {
          imgSrc: require("../../assets/images/swiper/swiper_2.jpg"),
        },
        {
          imgSrc: require("../../assets/images/swiper/swiper_3.jpg"),
        },
      ];
    },
    getSwiperListTwo() {
      this.swiperListTwo = [
        {
          imgSrc: require("../../assets/images/swiper/swiperItem_1.png"),
          title: "创新人才管理体系",
          content:
            "我们帮助这些企业实现了显著的业务成果一亿滋国际已节约10亿美元...",
        },
        {
          imgSrc: require("../../assets/images/swiper/swiperItem_2.png"),
          title: "创新人才管理体系",
          content:
            "我们帮助这些企业实现了显著的业务成果一亿滋国际已节约10亿美元...",
        },
        {
          imgSrc: require("../../assets/images/swiper/swiperItem_3.png"),
          title: "创新人才管理体系",
          content:
            "我们帮助这些企业实现了显著的业务成果一亿滋国际已节约10亿美元...",
        },
        {
          imgSrc: require("../../assets/images/swiper/swiperItem_1.png"),
          title: "创新人才管理体系",
          content:
            "我们帮助这些企业实现了显著的业务成果一亿滋国际已节约10亿美元...",
        },
        {
          imgSrc: require("../../assets/images/swiper/swiperItem_2.png"),
          title: "创新人才管理体系",
          content:
            "我们帮助这些企业实现了显著的业务成果一亿滋国际已节约10亿美元...",
        }
      ];
    },
    getSwiperListThree() {
      this.swiperListThree = [
        {
          imgSrc: require("../../assets/images/index/partner_1.png"),
        },
        {
          imgSrc: require("../../assets/images/index/partner_2.png"),
        },
        {
          imgSrc: require("../../assets/images/index/partner_3.png"),
        },
        {
          imgSrc: require("../../assets/images/index/partner_4.png"),
        },
        {
          imgSrc: require("../../assets/images/index/partner_5.png"),
        },
        {
          imgSrc: require("../../assets/images/index/partner_6.png"),
        },
      ];
    },
    getMobileGoodsPage() {
      this.swiperList = [
        {
          imgSrc: require("../../assets/images/mobileSwiper/index_1.png"),
        },
        {
          imgSrc: require("../../assets/images/mobileSwiper/index_2.png"),
        },
        {
          imgSrc: require("../../assets/images/mobileSwiper/index_3.png"),
        }
      ];
    },
    getPCorMobile() {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.getMobileGoodsPage();
        window.addEventListener("scroll", this.Scrollbottom, true);
      } else {
        this.getSwiperList();
      }
    },
    goDetail(item) {
      if(item.link) {
          window.open(item.link)
      }else {
          this.$router.push({path:'/newsDetail',query:{id:item.id}})
      }
    }
  },
};
</script>
<style lang="scss">
.index {
  padding-top: 92px;
  .swiperBox {
    position: relative;
    width: 100%;
    height: 600px;
    .box {
      width: 100%;
      height: 600px;
      .swiper-container {
        overflow: hidden;
        width: 100%;
        position: relative;
        .swiper-wrapper {
          .swiper-slide {
            height: 600px;
            img {
              width: 100%;
              height: 600px;
            }
          }
        }
      }
    }
    .swiper-pagination {
      width: 100%;
      margin-top: -50px;
      position: absolute;
      left: 50%;
      bottom: 5%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: #fff;
        opacity: 0.9;
        margin-right: 10px;
      }
      .swiper-pagination-bullet-active {
        background-color: #197dbe;
      }
    }
  }
  .production {
    .section {
      .title {
        text-align: center;
        padding: 105px 0 95px 0;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 36px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        .left_one {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 192px;
          .images {
            width: 102px!important;
            height: 102px!important;
            background: url('../../assets/images/index/index_1.png') no-repeat center center;
            background-size:102px 102px ;
            transition: all 500ms linear;
          }
          .one {
            padding: 34px 0;
            font-size: 18px;
            transition: all 500ms linear;
          }
          .two {
            color: #999999;
            font-size: 18px;
            margin-bottom: 20px;
            transition: all 500ms linear;
          }
          .three {
            color: #999999;
            font-size: 16px;
            line-height: 24px;
            transition: all 500ms linear;
          }
        }
        .left_one:hover {
          .images {
            background: url('../../assets/images/index/indexSelect_1.png') no-repeat center center;
            background-size:102px 102px ;
          }
          .one {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .two {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .three {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
        }
        .left_two {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 192px;
          .images {
            width: 102px!important;
            height: 102px!important;
            background: url('../../assets/images/index/index_2.png') no-repeat center center;
            background-size:102px 102px ;
            transition: all 500ms linear;
          }
          .one {
            padding: 34px 0;
            font-size: 18px;
            transition: all 500ms linear;
          }
          .two {
            color: #999999;
            font-size: 18px;
            margin-bottom: 20px;
            transition: all 500ms linear;
          }
          .three {
            color: #999999;
            font-size: 16px;
            line-height: 24px;
            transition: all 500ms linear;
          }
        }
        .left_two:hover {
          .images {
            background: url('../../assets/images/index/indexSelect_2.png') no-repeat center center;
            background-size:102px 102px ;
          }
          .one {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .two {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .three {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
        }
        .left_three {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 192px;
          .images {
            width: 102px!important;
            height: 102px!important;
            background: url('../../assets/images/index/index_3.png') no-repeat center center;
            background-size:102px 102px ;
            transition: all 500ms linear;
          }
          .one {
            padding: 34px 0;
            font-size: 18px;
            transition: all 500ms linear;
          }
          .two {
            color: #999999;
            font-size: 18px;
            margin-bottom: 20px;
            transition: all 500ms linear;
          }
          .three {
            color: #999999;
            font-size: 16px;
            line-height: 24px;
            transition: all 500ms linear;
          }
        }
        .left_three:hover {
          .images {
            background: url('../../assets/images/index/indexSelect_3.png') no-repeat center center;
            background-size:102px 102px ;
          }
          .one {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .two {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .three {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
        }
        .left_four {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 192px;
          .images {
            width: 102px!important;
            height: 102px!important;
            background: url('../../assets/images/index/index_4.png') no-repeat center center;
            background-size:102px 102px ;
            transition: all 500ms linear;
          }
          .one {
            padding: 34px 0;
            font-size: 18px;
            transition: all 500ms linear;
          }
          .two {
            color: #999999;
            font-size: 18px;
            margin-bottom: 20px;
            transition: all 500ms linear;
          }
          .three {
            color: #999999;
            font-size: 16px;
            line-height: 24px;
            transition: all 500ms linear;
          }
        }
        .left_four:hover {
          .images {
            background: url('../../assets/images/index/indexSelect_4.png') no-repeat center center;
            background-size:102px 102px ;
          }
          .one {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .two {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
          .three {
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

          }
        }
      }
    }
  }
  .service {
    margin: 82px 0;
    background: url("../../assets/images/index/index_5.png") no-repeat center
      center;
    background-size: cover;
    height: 520px;
    .section {
      height: 520px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        text-align: center;
        color: #ffffff;
        margin-bottom: 62px;
        .title_one {
          font-size: 40px;
        }
        .title_two {
          font-size: 16px;
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 172px;
          img {
            width: 102px;
            margin-bottom: 52px;
            transition: all .5s ease-in-out;
          }
          .one {
            font-size: 18px;
            line-height: 36px;
          }
        }
        .left:hover{
          img {
            transform: rotateY(360deg);
          }
        }
      }
    }
  }
  .transfer {
    padding-bottom: 68px;
    .section {
      .title {
        text-align: center;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 36px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        display: flex;
        justify-content: center;
        img {
          height: 605px;
        }
      }
    }
  }
  .news {
    background-color: #f9f9f9;
    padding-bottom: 82px;
    .section {
      .title {
        padding: 82px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 40px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        position: relative;
        width: 100%;
        .box {
          .swiper-container {
            .swiper-wrapper {
              width: 388px;
              height: 403px;
              padding: 13px 9px;
              .swiper-slide {
                background-color: #fff;
                text-align: center;
                .img {
                  width: 370px;
                  height: 232px;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    transition: all 500ms linear;
                  }
                }
                .middle {
                  padding: 30px 22px 13px 22px;
                  .itemTitle {
                    font-size: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp:1;
                    -webkit-box-orient:vertical;
                  }
                  .itemContent {
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2; /* 显示的行数 */
                  }
                }
              }
              .swiper-slide:hover {
                .img {
                  img {
                    transform:scale(1.1);
                  }
                }
                .middle {
                  .itemTitle {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp:1;
                    -webkit-box-orient:vertical;
                  }
                  .itemContent {
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: left;
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2; /* 显示的行数 */
                  }
                }
              }
            }
          }
        }
        .swiper-paginations {
          text-align: center;
          width: 100%;
          margin-top: -50px;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background: #c5c3c3;
            opacity: 0.9;
            margin-right: 10px;
          }
          .swiper-pagination-bullet-active {
            background-color: #197dbe;
          }
        }
      }
    }
  }
  .customer {
    padding-bottom: 82px;
    .section {
      .title {
        padding: 82px 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        .title_one {
          span {
            font-size: 40px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .title_two {
          span {
            font-size: 16px;
            display: inline-block;
            background-image: linear-gradient(
              to right,
              #25a9e0,
              #187aba
            ); //最后三行为文字渐变CSS
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .content {
        .swiper-container {
          position: relative;
          padding: 0 10px;
          .swiper-wrapper {
            // transition-timing-function: ease-in-out;
            // width: 388px;
            height: 102px;
            // padding: 13px 9px;
            .swiper-slide {
              background-color: #fff;
              text-align: center;
              img {
                width: 210px !important;
                height: 102px !important;
              }
            }
          }
          .swiper-button-prev {
            position: absolute;
            left: 0;
            cursor: pointer;
            background: none;
            display: flex;
            align-items: center;
            img {
              width: 11px;
            }
          }
          .swiper-button-next {
            position: absolute;
            right: -15px;
            cursor: pointer;
            background: none;
            display: flex;
            align-items: center;
            img {
              width: 11px;
            }
          }
        }
        .swiper-container-free-mode {
          .swiper-wrapper {
            -webkit-transition-timing-function: ease-in-out; /*之前是ease-out*/
            -moz-transition-timing-function: ease-in-out;
            -ms-transition-timing-function: ease-in-out;
            -o-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .index {
    padding-top: 0;
    .swiperBox {
      height: 4rem;
      .box {
        height: 4rem;
        .swiper-container {
          overflow: hidden;
          width: 100%;
          position: relative;
          .swiper-wrapper {
            .swiper-slide {
              height: 4rem;
              img {
                width: 100%;
                height: 4rem;
              }
            }
          }
        }
      }
      .swiper-pagination {
        width: 100%;
        margin-top: -1rem;
        position: absolute;
        left: 50%;
        bottom: 5%;
        transform: translateX(-50%);
        .swiper-pagination-bullet {
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 0.2rem;
          background: #fff;
          opacity: 0.9;
          margin-right: 0.15rem;
        }
        .swiper-pagination-bullet-active {
          background-color: #197dbe;
        }
      }
    }
    .production {
      padding-bottom: 0.6rem;
      .section {
        .title {
          padding: 0.6rem 0 0.4rem 0;
          .title_one {
            span {
              font-size: 0.5rem;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
            }
          }
        }
        .content {
          flex-wrap: wrap;
          .left_one, .left_two, .left_three, .left_four {
            width: 3.2rem;
            .images {
              width: 1.5rem!important;
              height: 1.5rem!important;
              background-size:1.5rem 1.5rem ;
            }
            .one {
              padding: 0.25rem 0 0.1rem 0;
              font-size: 0.38rem;
            }
            .two {
              margin-bottom: 0;
              line-height: 0.6rem;
              font-size: 0.3rem;
            }
            .three {
              line-height: 0.45rem;
              font-size: 0.28rem;
            }
          }
          .left_one:hover {
            .images {
              width: 1.5rem!important;
              height: 1.5rem!important;
              background-size:1.5rem 1.5rem ;
            }
          }
          .left_two:hover {
            .images {
              width: 1.5rem!important;
              height: 1.5rem!important;
              background-size:1.5rem 1.5rem ;
            }
          }
          .left_three:hover {
            .images {
              width: 1.5rem!important;
              height: 1.5rem!important;
              background-size:1.5rem 1.5rem ;
            }
          }
          .left_four:hover {
            .images {
              width: 1.5rem!important;
              height: 1.5rem!important;
              background-size:1.5rem 1.5rem ;
            }
          }
        }
      }
    }
    .mobile_service {
      background: url("../../assets/images/mobile/index/index_3.png") no-repeat
        center center;
      background-size: contain;
      height: 6.8rem;
      .section {
        .title {
          padding: 0.6rem 0 0.4rem 0;
          color: #ffffff;
          text-align: center;
          .title_one {
            font-size: 0.5rem;
          }
          .title_two {
            font-size: 0.2rem;
          }
        }
        .content {
          display: flex;
          flex-wrap: wrap;
          .left {
            text-align: center;
            width: 2.2rem;
            margin-right: 0.25rem;
            color: #ffffff;
            margin-bottom: 0.4rem;
            img {
              width: 1.2rem;
            }
            .one {
              text-align: left;
              font-size: 0.25rem;
            }
          }
          .left:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
    .transfer {
      padding-bottom: 0.6rem;
      .section {
        .title {
          padding: 0.6rem 0 0.4rem 0;
          .title_one {
            span {
              font-size: 0.5rem;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
            }
          }
        }
        .content {
          width: 100%;
          img {
            width: 100%;
            height: 6rem;
          }
        }
      }
    }
    .mobile_news {
      background-color: #f9f9f9;
      padding-bottom: 1rem;
      .section {
        .title {
          padding: 0.6rem 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          .title_one {
            span {
              font-size: 0.5rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .content {
          position: relative;
          .swiper-container {
            .swiper-wrapper {
              width: 4rem;
              .swiper-slide {
                background-color: #fff;
                text-align: center;
                // img {
                //   width: 4rem !important;
                // }
                .img {
                  width: 4rem !important;
                  height: 2.5rem;
                  overflow: hidden;
                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    transition: all 500ms linear;
                  }
                }
                .middle {
                  padding: .2rem;
                  .itemTitle {
                    // height: .5rem;
                    font-size: .3rem;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 1; /* 显示的行数 */
                  }
                  .itemContent {
                    height: 1rem;
                    margin-top: .2rem;
                    font-size: .3rem;
                    line-height: .5rem;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2; /* 显示的行数 */
                  }
                }
              }
              .swiper-slide-active {
                .img {
                  img {
                    transform:scale(1.1);
                  }
                }
                .middle {
                  .itemTitle {
                    font-size: .3rem;
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 1; /* 显示的行数 */
                  }
                  .itemContent {
                    margin-top: .2rem;
                    font-size: .3rem;
                    line-height: .5rem;
                    text-align: left;
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    overflow: hidden;
                    text-overflow: ellipsis;  /* 超出部分省略号 */
                    word-break: break-all;  /* break-all(允许在单词内换行。) */  
                    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
                    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                    -webkit-line-clamp: 2; /* 显示的行数 */
                  }
                }
              }
            }
          }
          .swiper-paginations {
            margin-top: -1rem;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 140%);
            .swiper-pagination-bullet {
              width: .15rem;
              height: .15rem;
              border-radius: .15rem;
              background: #c5c3c3;
              opacity: 0.9;
              margin-right: .05rem;
            }
            .swiper-pagination-bullet-active {
              background-color: #197dbe;
            }
          }

        }
      }
    }
    .mobile_customer {
      padding-bottom: .6rem;
      .section {
        .title {
          padding: 0.6rem 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          .title_one {
            span {
              font-size: 0.5rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .title_two {
            span {
              font-size: 0.2rem;
              display: inline-block;
              background-image: linear-gradient(
                to right,
                #25a9e0,
                #187aba
              ); //最后三行为文字渐变CSS
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .content {
          .swiper-container {
          position: relative;
          padding: 0 .2rem;
          overflow: hidden;
          .swiper-wrapper {
            // width: 388px;
            height: 1rem;
            // padding: 13px 9px;
            .swiper-slide {
              background-color: #fff;
              text-align: center;
              img {
                // width: 210px !important;
                height: 1rem !important;
              }
            }
          }
          .swiper-button-prev {
            position: absolute;
            left: 0;
            cursor: pointer;
            background: none;
            display: flex;
            align-items: center;
            img {
              width: .15rem;
            }
          }
          .swiper-button-next {
            position: absolute;
            right: -.34rem;
            cursor: pointer;
            background: none;
            display: flex;
            align-items: center;
            img {
              width: .15rem;
            }
          }
        }
        }
      }
    }
  }
}
</style>